export const SERVER_URL = process.env.REACT_APP_API_URL;

export const CURRENCIES = [
  { value: "", label: "All" },
  { value: 'AED', label: 'AED' },
  { value: 'USD', label: 'USD' },
  { value: 'TRY', label: 'TRY' },
  { value: 'AZN', label: 'AZN' },
  { value: 'EGP', label: 'EGP' },
  { value: 'SAR', label: 'SAR' },
];

export const SALES_STATUSES = [
  { value: undefined, label: 'All' },
  { value: "0", label: "Awaiting shipment" },
  { value: "1", label: "Pending customer approval" },
  { value: "2", label: " Refund requested" },
  { value: "3", label: " Shipment completed" },
];

export const CHARGEBACK_STATUSES = [
  { value: undefined, label: 'All' },
  { value: true, label: 'Show only chargebacks' },
];
